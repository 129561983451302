
<template>
  <div class="d-flex flex-row align-center">
    <slot name="status">
      <v-chip
        v-if="status.isBeyondTarget"
        :color="status.isBeyondTarget ? 'error' : 'primary'"
        :outlined="outlinedChip"
        :small="smallChip"
        :large="largeChip"
        class="mr-3"
      >
        <div
          class="mr-1 "
          v-if="status.workflow"
        >
          <v-icon
            x-small
            class="mr-1"
          >{{$icon('i.Workflow')}}</v-icon>
          <span>{{status.workflow.name}}</span>
        </div>
        <document-name-ref
          :id="status.id"
          :cache-type="cacheTypeStatusDispute"
        />
      </v-chip>
      <div v-else>
        <div
          class="mr-1"
          v-if="status.workflow"
        >
          <v-icon
            small
            class="mr-1"
          >{{$icon('i.Workflow')}}</v-icon>
          <span>{{status.workflow.name}}</span>
        </div>
        <document-name-ref
          :id="status.id"
          :cache-type="cacheTypeStatusDispute"
        />
      </div>
    </slot>
    <span class="ml-1">{{daysSummary}}</span>
    <div
      class="ml-2"
      v-if="status.allocationDispute"
    >
      <span>{{status.allocationDispute.status}}</span>
      <span class="ml-1">{{formatDate(status.allocationDispute.date)}}</span>
    </div>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
export default {
  components: {
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    daysSummary () {
      if (!this.status.isClosed) {
        const daysBeforeTarget = this.moment(this.status.targetDate).diff(this.moment().startOf('day'), 'days')
        return daysBeforeTarget > 0
          ? daysBeforeTarget + ' ' + this.$t('t.DaysBeforeDate')
          : daysBeforeTarget < 0
            ? -daysBeforeTarget + ' ' + this.$t('t.DaysPastDue')
            : this.$t('t.DeadlineToday')
      } else {
        const daysBeforeTarget = this.moment(this.status.targetDate).diff(this.moment(this.status.statusChangedAt), 'days')
        return daysBeforeTarget < 0 ? -daysBeforeTarget + ' ' + this.$t('t.DaysPastDue') : ''
      }
    }
  },
  data () {
    return {
      cacheTypeStatusDispute: CacheType.StatusDisputeRef
    }
  },
  props: {
    status: Object,
    smallChip: Boolean,
    largeChip: Boolean,
    outlinedChip: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
